<template>
  <!-- 路径【入库管理】>>>【入库单管理】（详情）>>>打印货品标签 -->
  <div class="printWj">
    <div class="print-item">
      <div class="print-flex">
        <nh-barcode
          v-if="list.onceContainerCode && codeType=== DICTIONARY.BAR_CODE"
          :value="list.onceContainerCode"
          :text="list.onceContainerCode"
          size="big"
          :width="1.3"
          :height="40"
        />
        <div v-else-if="list.onceContainerCode" class="qrcode">
          <nh-qrcode :text="list.onceContainerCode" />
          <p v-text="list.onceContainerCode" />
        </div>

        <div>
          <p>推荐库位</p>
          <p class="bold fontSize20">
            {{ list.assignedLocationCode }}
          </p>
        </div>
      </div>
      <div class="body">
        <el-row>
          <el-col :span="7">
            货品名称:
          </el-col>
          <el-col :span="17" class="weight600 print-title-goods">
            {{ list.goodsName }}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            货主:
          </el-col>
          <el-col :span="17">
            {{ list.shipperName }}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            货主货品编码:
          </el-col>
          <el-col :span="17">
            {{ list.shipperGoodsCode }}
          </el-col>
        </el-row>
        <el-row v-if="list.thirdProCode">
          <el-col :span="7">
            第三方货品编码:
          </el-col>
          <el-col :span="17">
            {{ list.thirdProCode }}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            规格:
          </el-col>
          <el-col :span="17">
            {{ list.goodsSpecifications }}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            入库时间:
          </el-col>
          <el-col :span="6">
            {{ list.storeInTime }}
          </el-col>
          <el-col :span="5">
            生产日期:
          </el-col>
          <el-col :span="6">
            {{ list.productDate }}
          </el-col>
        </el-row>
        <el-row v-if="list.boxNo">
          <el-col :span="7">
            柜号:
          </el-col>
          <el-col :span="17">
            {{ list.boxNo }}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            码盘:
          </el-col>
          <el-col :span="17" class="weight600 print-title-goods">
            {{ list.palletRule }}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            温层:
          </el-col>
          <el-col :span="11">
            {{ list.temperatureLayerName }}
          </el-col>
          <el-col :span="6" class="weight600 print-title-goods">
            {{ list.amount }}
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import moment from '@/utils/moment';
import { YMD } from '@/constant/timeFormat';
import { DICTIONARY } from '../constant';

export default {
  name: 'PrintGoodsLabelCustomized2',
  props: {
    codeType: {
      type: String,
      default: '',
    },
    printList: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      DICTIONARY,
      list: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.list = {
        ...this.printList,
        storeInTime: moment.format(this.printList.storeInTime, YMD),
        productDate: moment.format(this.printList.productDate, YMD),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: 600;
}

.fontSize20 {
  font-size: 20px;
}

.printWj {
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
}

.print-title-goods {
  font-size: 14px;
}

.print-item {
  padding: 8px;
  page-break-after: always;
  flex: 0 0 360px;

  .print-flex {
    display: flex;
    justify-content: space-between;
  }

  .qrcode {
    text-align: center;
  }

  .body {
    margin-top: 16px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;

    .el-col {
      padding: 6px 0 0 4px;
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
    }

    .weight600 {
      font-weight: 600;
    }
  }
}

@media print {
  .printWj {
    display: block;
  }
}
</style>
