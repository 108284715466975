<template>
  <!-- 路径【入库管理】>>>【入库单管理】（详情）>>>打印货品标签 -->
  <div class="printWj">
    <div class="aspectration">
      <div class="print-item">
        <div ref="printHeader" class="print-flex">
          <nh-barcode
            v-if="list.onceContainerCode && codeType=== DICTIONARY.BAR_CODE"
            :value="list.onceContainerCode"
            :text="list.onceContainerCode"
            size="big"
            :width="1.8"
            :height="60"
          />
          <div v-else-if="list.onceContainerCode" class="qrcode">
            <nh-qrcode
              :text="list.onceContainerCode"
              :size="qrSize"
            />
            <p v-text="list.onceContainerCode" />
          </div>
        </div>
        <div class="body">
          <el-row class="size-small">
            <el-col :span="4">
              <span>品名:</span>
            </el-col>
            <el-col :span="20">
              <span>{{ list.goodsName }}</span>
            </el-col>
          </el-row>
          <el-row class="size-small">
            <el-col :span="4">
              <span>货主:</span>
            </el-col>
            <el-col :span="20">
              <span>{{ list.shipperName }}</span>
            </el-col>
          </el-row>
          <el-row class="size-small">
            <el-col :span="4">
              <span>编码:</span>
            </el-col>
            <el-col :span="7">
              <span>{{ list.shipperGoodsCode }}</span>
            </el-col>
            <el-col :span="5">
              <span>生产日期:</span>
            </el-col>
            <el-col :span="8">
              <span>{{ list.productDate }}</span>
            </el-col>
          </el-row>
          <el-row class="size-small">
            <el-col :span="4">
              <span>规格:</span>
            </el-col>
            <el-col :span="7">
              <span>{{ list.goodsSpecifications }}</span>
            </el-col>
            <el-col :span="5">
              <span> 到期时间:</span>
            </el-col>
            <el-col :span="8">
              <span>{{ list.expireDate }}</span>
            </el-col>
          </el-row>
          <el-row class="size-small">
            <el-col :span="4">
              <span>柜号:</span>
            </el-col>
            <el-col :span="7">
              <span>{{ list.boxNo }}</span>
            </el-col>
            <el-col :span="5">
              <span>客户批号:</span>
            </el-col>
            <el-col :span="8">
              <span>{{ list.customerBatch }}</span>
            </el-col>
          </el-row>
          <el-row class="size-small">
            <el-col :span="4">
              <span>码盘:</span>
            </el-col>
            <el-col :span="7" class="weight600">
              <span>{{ list.palletRule }}</span>
            </el-col>
            <el-col :span="5">
              温层:
            </el-col>
            <el-col :span="8">
              {{ list.temperatureLayerName }}
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <p class="bold fontSize18">
                库位:{{ list.assignedLocationCode }}
              </p>
            </el-col>
            <el-col :span="6" class="weight600 print-title-goods">
              {{ list.amount }}
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from '@/utils/moment';
import { YMD } from '@/constant/timeFormat';
import { DICTIONARY } from '../constant';

export default {
  name: 'PrintGoodsLabelCustomized2',
  props: {
    codeType: {
      type: String,
      default: '',
    },
    printList: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      DICTIONARY,
      list: [],
      qrSize: 80,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.list = {
        ...this.printList,
        storeInTime: moment.format(this.printList.storeInTime, YMD),
        productDate: moment.format(this.printList.productDate, YMD),
        expireDate: moment.format(this.printList.expireDate, YMD),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: 600;
}

.fontSize18 {
  word-break: break-all;
  font-size: 18px;
}

.printWj {
  width: 8cm;
  height: 6cm;
  font-size: 13px;

  .aspectration {
    height: 100%;
    width: 100%;
    page-break-after: always;
    padding: 13px;
  }
}

.print-title-goods {
  font-size: 14px;
}

.print-item {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding-right: 0.2cm;

  .print-flex {
    flex: 200;
    display: flex;
    justify-content: space-between;
  }

  .qrcode {
    position: relative;
    padding-bottom: 12px;

    p {
      position: absolute;
      font-size: 12px;
      left: 0;
      transform: scale(0.8);
      transform-origin: left bottom;
    }
  }

  .body {
    flex: 800;
    display: flex;
    flex-direction: column;
    margin-top: 6px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;

    .el-row {
      flex: 1;
    }

    .el-col {
      padding-left: 6px;
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .weight600 {
      font-weight: 600;
    }
  }
}

@page {
  size: auto;
  margin: 0;
}

@media print {
  .printWj {
    width: 8cm;
  }
}
</style>
<style lang="scss">
.aspectration {
  .print-item {
    .body {
      .size-small {
        font-size: 12px;
        flex: 0.6;

        .el-col {
          position: relative;

          span {
            position: absolute;
            box-sizing: border-box;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding-left: 6px;
            transform: scale(0.8);
            transform-origin: 6px 50%;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

</style>
