<template>
  <div v-loading="loading.init" class="print-page">
    <div class="print-btn">
      <PrintButton
        :type="PRINT_BUTTON.type"
      />
      <div class="margin-24">
        <el-radio-group v-model="form.codeType">
          <el-radio
            v-for="item in CODE_TYPE"
            :key="item.value"
            :label="item.value"
          >
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </div>
    </div>
    <div v-for="(printList, printNumber) in prints" :key="printNumber">
      <div v-for="item in printNum" :key="item">
        <component
          :is="printList.component"
          :print-list="printList"
          :code-type="form.codeType"
        />
      </div>
    </div>
  </div>
</template>

<script>
import loadingMixin from '@thales/loading';

import { mapState } from 'vuex';
import PrintButton from '../components/PrintButton/PrintButton.vue';
import Normal from './normal/index.vue';
import Customized1 from './customized1/index.vue';

import { PRINT_BUTTON } from '../components/PrintButton/config';

import config, { ShipperCodes } from './config';
import { DICTIONARY, CODE_TYPE } from './constant';

export default {
  components: {
    PrintButton,
    Normal,
    Customized1,
  },
  mixins: [loadingMixin],
  data() {
    return {
      PRINT_BUTTON,
      config,
      DICTIONARY,
      CODE_TYPE,
      prints: [],
      printNum: 1,
      form: {
        codeType: DICTIONARY.BAR_CODE,
      },
      loading: {
        init: false,
      },
    };
  },
  computed: {
    ...mapState({
      accountInfo: ({ user }) => user.accountInfo,
    }),
  },
  created() {
    const { tableLists, printNum = 1 } = this.$route.query;
    this.printNum = Number(printNum);
    this.init(tableLists);
  },
  methods: {
    async init(tableLists) {
      const component = ShipperCodes.includes(this.accountInfo.tenantId)
        ? config.CUSTOM1 : config.DEFAULT;
      const resp = JSON.parse(tableLists);
      this.prints = resp.map((item) => ({
        ...item,
        component, // 打印样式
      }));
    },
  },
};
</script>
<style lang="scss" scoped>
.print-page {
  max-width: 1123px;
  margin: 0 auto;
}

.print-btn {
  display: flex;
  align-items: baseline;

  .margin-24 {
    margin-left: 24px;
  }
}

@media print {
  .print-btn {
    display: none;
  }

  .afterFooter {
    display: block;
  }
}
 </style>
