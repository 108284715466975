const TEMPLATES = {
  DEFAULT: 'normal', // 默认样式
  CUSTOM1: 'customized1',
};

export default TEMPLATES;

// 临时方案
const ShipperCodes = ['T10000142001', 'T10000142002', 'T10200085', 'T10110033'];
export { ShipperCodes };
